import React, { useState } from 'react'
import Header from '../../components/header'
import Sidebar from '../../components/Sidebar'
// import { useGetProfile } from '../../hooks/profile/profile'

const MainLayout = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const onClose = () => setIsOpen(false)
  const onOpen = () => setIsOpen(true)
  // useGetProfile()
  return (
    <div className='w-full h-screen flex'>
      <Sidebar isOpen={isOpen} onClose={onClose} />
      <div className='w-full md:w-[80%] lg:w-[85%] text-white relative h-full overflow-y-auto'>
        <Header isOpen={isOpen} onOpen={onOpen} />
        <div className='w-full p-5 md:px-10'>
          {children}
        </div>
      </div>
    </div>
  )
}

export default MainLayout
