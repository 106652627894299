/* eslint-disable react-hooks/exhaustive-deps */
import * as FileSaver from 'file-saver'
import { Button, Modal } from 'antd'
import * as XLSX from 'xlsx'
import { useRecoilState } from 'recoil'
import { pageTitleAtom } from '../atoms/others/others.atom'
import { FaRegCircleCheck } from 'react-icons/fa6'

export const formatNumber = num => {
  if (num !== undefined) {
    return parseFloat(Number(num).toFixed(2))
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
  return 0
}

export const formatCurrency = (value, currency, unit=true) =>
  new Intl.NumberFormat(`en-${currency?.slice(0, 2) || "NG"}`, {
    style: "currency",
    currency: currency || "NGN",
  }).format(value).replace(".00", unit ? ".00" : "");

  export const handleDataExport = (name, parseData) => {
    const ws = XLSX.utils.json_to_sheet(parseData)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    })
    FileSaver.saveAs(data, `${name}.xlsx`)
  }
  
  export const handleDataReduce = (data, key) => {
    return data?.reduce((a, b) => Number(a) + Number(b?.[key] || b), 0)
  }

  export const handleHash = (data, index) => {
    const mailName = String(data||"")?.slice(0,String(data||"")?.indexOf("@") || index)
    const mailProvider = String(data||"")?.slice(String(data||"")?.indexOf("@") || index)
    const mailNameCut = mailName.slice(0, Math.floor(mailName.length/2))
    return mailNameCut.padEnd(mailName.length, "*").concat(mailProvider) || ""
  }

  export const handleShrink = (data) => <p className='w-10 truncate' title={data}>{handleHash(data, data?.length/2)}</p>

  export const handleCapitalize = (data) => {
    return String(data||"")?.charAt(0)?.toUpperCase() + String(data||"")?.slice(1);
  }

  export function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (err) => reject(err);
    });
  }

export function getBinary(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const data=(reader.result).split(',')[1];
      resolve(atob(data));
    }
    reader.onerror = (err) => reject(err)
  })
}

export const statusType = {
  secondary: { col: "!text-[#3E4A5F]", bg: "!bg-[#878A991F]", bd: "!border !border-[#495057]", },
  pending: { col: "!text-[#FFBD00]", bg: "!bg-[#FFBD001F]",  bd: "!border !border-[#FFBD00]" },
  amber: { col: "!text-[#F7941D]", bg: "!bg-[#F7941D1F]",  bd: "!border !border-[#F7941D]" },
  inactive: { col: "!text-[#E01020]", bg: "!bg-[#E010201F]", bd: "!border !border-[#E01020]" },
  success: { col: "!text-[#12B76A]", bg: "!bg-[#12B76AF]", bd: "!border !border-[#12B76A]" },
  active: { col: "!text-[#12B76A]", bg: "!bg-[#12B76AF]", bd: "!border !border-[#12B76A]" },
  green: { col: "!text-[#4AC97E]", bg: "!bg-[#4AC97E1F]", bd: "!border !border-[#4AC97E]" },
  error: { col: "!text-[#E01020]", bg: "!bg-[#E010201F]", bd: "!border !border-[#E01020]" },
  info: { col: "!text-[#004EC4]", bg: "!bg-[#E6EDF9]", bd: "!border !border-[#004EC4]" },
}

export const handleTableSelect = (data, setData, key) => ({
    type: "checkbox",
    selectedRowKeys: data,
    onChange: (e, selectedRows) => {
      const arr = [];
      selectedRows.forEach(row => arr.push(row?.[key] || row));
      setData(arr);
    },
})

export const successModal = ({
  icon,
  title,
}) => {
  Modal.success({
    footer: false,
    icon: <span />,
    closeIcon: false,
    maskClosable: true,
    className: 'md:!w-[350px] rounded-lg !min-h-[350px] !relative px-10',
    content: (<div className='flex flex-col justify-center items-center text-center gap-5 absolute left-0 right-0'>
      <p className='text-2xl font-bold text-light_blue'>{title || "Saved"}</p>
    </div>)
  });
};

export const handleConfirm = ({
  data,
  icon,
  title,
  action,
  loading,
  description,
}) => {
  const onClose = () => Modal.destroyAll()
  const handleAction = () => {
    action(data);
    onClose();
  }
  Modal.confirm({
    footer: false,
    icon: <span />,
    closeIcon: false,
    maskClosable: true,
    styles: {content: {background: "transparent"}},
    className: 'md:!w-[550px] rounded-lg !min-h-[350px] !relative px-10 !bg-transparent',
    content: (<div className='w-full p-5 sm:p-10 bg-[#1A1D21F5] space-y-5 absolute left-0 right-0 rounded-xl'>
      <p className='text-2xl font-medium text-[#E8E9E9]'>{title || "Take Action?"}</p>
      <p className='text-base font-medium text-[#CDCECF]'>{description || "Are you sure you want to proceed with this action?"}</p>
      <div className='w-full flex flex-col md:flex-row items-center gap-3'>
        <Button onClick={onClose} className='text-[#686B6E]' type='text' size='large'>Cancel</Button>
        <Button onClick={handleAction} className='bg-primary text-[#060708]' loading={loading} size='large' type='primary' icon={<FaRegCircleCheck />}>Proceed</Button>
      </div>
    </div>)
  });
};

export const stringify = (data) => JSON.stringify(data)

export const parse = (data) => JSON.parse(data)

export const realObject = (data) => Object.fromEntries(Object.entries(data || {}).filter(([,value]) => value))

export const include = (data, key) => data?.includes(key)

export const isEqual = (data, key) => (data === key)

export const usePageTitle = ({actBtn, backBtn, backPath, actIcon, actTarget, actText, title}) => {
  const [pageTitle, setPageTitle] = useRecoilState(pageTitleAtom)
  const handleSet = () => setPageTitle({
    ...pageTitle,
    title: title || "",
    actIcon: actIcon || "",
    actText: actText || "",
    actBtn: actBtn || false,
    backPath: backPath || "",
    backBtn: backBtn || false,
    actTarget: actTarget || "",
  })
  return {pageTitle, handleSet}
}
