import { RecoilRoot } from "recoil";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import AxiosContextProvider from "./context/AxiosContext";
import CustomRoutes from "./router";
import { ConfigProvider } from "antd";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 60, // 1 hour in ms
      cacheTime: 1000 * 60 * 60, // 1 hour in ms
      refetchOnWindowFocus: false, // Disables automatic refetching when browser window is focused.
    },
  },
});
function App() {
  return (
    <RecoilRoot>
      <Router>
        <AxiosContextProvider>
          <QueryClientProvider client={queryClient}>
            <ConfigProvider
              theme={{
                components: {
                  Empty: { colorText: "#fff" },
                  Spin: { colorPrimary: "#F7941D" },
                  Menu: { colorPrimary: "#F7941D" },
                  Switch: { colorPrimary: "#F7941D" },
                  Checkbox: { colorPrimary: "#F7941D" },
                  Form: { labelColor: "#9B9C9E", labelFontSize: 16 },
                  Button: { colorPrimary: "#F7941D", colorPrimaryHover: "#F7941D" },
                  Steps: { colorPrimary: "#686B6E", colorTextDescription: "#fff", colorText: "#fff" },
                  DatePicker: { colorBgContainer: "#131619", colorBorder: "#1A1D21", colorTextPlaceholder: "#9B9C9E" },
                  Table: { colorBgContainer: "transparent", colorTextDescription: "#fff", headerSplitColor: "transparent" },
                  Input: { colorBgContainer: "#131619", colorBorder: "#1A1D21", colorTextPlaceholder: "#9B9C9E", colorText: "#fff" },
                  Select: { colorBgContainer: "#131619", colorBorder: "#1A1D21", colorTextPlaceholder: "#9B9C9E", colorText: "#999" },
                  Modal: { contentBg: "#1A1D21F5", headerBg: "#1A1D21F5", footerBg: "#1A1D21F5", colorText: "#fff", colorTextHeading: "#E8E9E9" },
                  Tabs: { colorPrimary: "#F7941D", colorPrimaryHover: "#f7941d", colorBorder: "#1A1D21", colorBorderSecondary: "#1A1D21", colorText: "#fff", colorIcon: "#fff" },
              }}}>
              <CustomRoutes />
            </ConfigProvider>
          </QueryClientProvider>
        </AxiosContextProvider>
      </Router>
    </RecoilRoot>
  );
}

export default App;
