import { Image } from 'antd'
import React from 'react'
import logo from "../assets/logo.png"
import { Link } from 'react-router-dom'

function Logo({ hidden, path, className  }) {
  return (
    <Link to={path || "/"} hidden={hidden} className={`w-full block text-center ${className}`}>
      <Image alt='logo' src={logo} preview={false} hidden={hidden} />
    </Link>
  )
}

export default Logo