import axios from "axios";
import { useRecoilValue } from "recoil";
import { useMemo, createContext } from "react";
import authAtom from "../atoms/auth/auth.atom";
import { message } from "antd";

const { href: host } = window.location;
export const AxiosContext = createContext(null);
export const isTestEnv = host.includes("localhost") || host.includes("surge") || host.includes("vercel");
const isDevEnv =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development" || isTestEnv;
  
export const baseURL = isDevEnv
  ? "http://sandbox.bitbenderapp.com/api/v1/"
  : "https://api.bitbenderapp.com/api/v1/";

export default function AxiosContextProvider({ children }) {
  const { isLoggedIn, user } = useRecoilValue(authAtom);
  const axiosInstance = useMemo(() => {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    if (isLoggedIn) {
      headers.Authorization = `Bearer ${user?.accessToken}`;
    }
    return axios.create({ baseURL, headers });
  }, [isLoggedIn, user]);

  axiosInstance.interceptors.response.use(
    (response) =>
      new Promise((resolve, reject) => {
        resolve(response);
      }),
    (error) => {
      if (!error.response) {
        window.location.href = "/auth/login";      
        message.error({
          content: 'Token has expired, please re-login to continue',
          duration: 4,
          key: 'updatable',
        })
      }
      // console.log(error.response.status,)
      // console.log(error,)
      if (error?.response?.status === 400 || error?.response?.status > 401) {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      } else {
        window.location.href = "/auth/login";      
        message.error({
          content: 'Token has expired, please re-login to continue.',
          duration: 4,
          key: 'updatable',
        })
      }
    }
  );


  return (
    <AxiosContext.Provider value={axiosInstance}>
      {children}
    </AxiosContext.Provider>
  );
}
