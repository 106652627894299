import { lazy } from "react";


// authentication pages imports 
const LoginPage = lazy(() => import("../pages/auth/login"));
const CreatePromoTeamPage = lazy(() => import("../pages/auth/create-promo-team"));
const ForgotPage = lazy(() => import("../pages/auth/forgot"));
const ResetPage = lazy(() => import("../pages/auth/reset"));
const OTPPage = lazy(() => import("../pages/auth/otp"));
const ConfirmPage = lazy(() => import("../pages/auth/confirm"));
const LogoutPage = lazy(() => import("../pages/auth/logout"));
const DeleteUserAccount = lazy(() => import("../pages/auth/delete-user"));


// main pages imports 
const Dashboard = lazy(() => import("../pages/main/dashboard"));
const Revenue = lazy(() => import("../pages/main/revenue"));
const Users = lazy(() => import("../pages/main/users"));
const Celebrities = lazy(() => import("../pages/main/celebrities"));
const PromoTeam = lazy(() => import("../pages/main/promoteam"));
const Shoutouts = lazy(() => import("../pages/main/shoutouts"));
const SongPromos = lazy(() => import("../pages/main/songpromos"));
const Reports = lazy(() => import("../pages/main/reports"));
const PackageManager = lazy(() => import("../pages/main/packagemanager"));
const Withdrawals = lazy(() => import("../pages/main/withdrawals"));
const Settings = lazy(() => import("../pages/main/settings"));
const TransactionPage = lazy(() => import("../pages/main/transactions"));


export const routes = [
  // main pages with no initial path
  {
    path: "/",
    label: "Dashboard",
    component: <Dashboard />
  },
  {
    path: "/revenue",
    label: "Revenue",
    component: <Revenue />
  },
  {
    path: "/users",
    label: "Users",
    component: <Users />
  },
  {
    path: "/celebrities",
    label: "Celebrities",
    component: <Celebrities />
  },
  {
    path: "/promo-teams",
    label: "PromoTeam",
    component: <PromoTeam />
  },
  {
    path: "/shoutouts",
    label: "Shout Out",
    component: <Shoutouts />
  },
  {
    path: "/song-promos",
    label: "Song Promos",
    component: <SongPromos />
  },
  {
    path: "/reports",
    label: "Reports",
    component: <Reports />
  },
  {
    path: "/package-manager",
    label: "Package Manager",
    component: <PackageManager />
  },
  {
    path: "/withdrawals",
    label: "Withdrawals",
    component: <Withdrawals />
  },
  {
    path: "/transactions",
    label: "Transactions",
    component: <TransactionPage />
  },
  {
    path: "/settings",
    label: "Settings",
    component: <Settings />
  },



  // authentication pages with initial path of /auth
  {
    label: "Login",
    path: "/auth/login",
    component: <LoginPage />
  },
  {
    label: "Forget Password",
    path: "/auth/forgot-password",
    component: <ForgotPage />
  },
  {
    label: "Reset",
    path: "/auth/reset-password",
    component: <ResetPage />
  },
  {
    label: "OTP",
    path: "/auth/otp",
    component: <OTPPage />
  },
  {
    label: "Confirm Mail",
    path: "/auth/confirm",
    component: <ConfirmPage />
  },
  {
    label: "Logout",
    path: "/auth/logout",
    component: <LogoutPage />
  },
  {
    label: "Create Promo Team",
    path: "/auth/create-promo-team",
    component: <CreatePromoTeamPage />
  },
  {
    label: "Delete User Account",
    path: "/auth/delete-user-account",
    component: <DeleteUserAccount />
  },
]