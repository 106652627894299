import React from 'react'
import Logo from '../../components/logo'

const AuthLayout = ({
  children,
}) => {
  return (
    <div className='w-full flex justify-center items-center bg-blue py-10 sm:p-10 md:px-20' style={{minHeight: "100vh"}}>
      <div className='w-full md:w-[80%] lg:w-[50%] mx-auto py-10 space-y-5 rounded-xl md:shadow-md'>
        <Logo />
        {children}
      </div>
    </div>
  )
}

export default AuthLayout
