import { atom } from "recoil";

export const pageTitleAtom = atom({
  key: "page-title",
  default: {
    backBtn: false,
    actIcon: false,
    actBtn: false ,
    actTarget: "",
    backPath: "",
    actText: "",
    title: "",
    search: ""
  },
});
