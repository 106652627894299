import React from 'react'
import Logo from './logo'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { GoGear } from 'react-icons/go'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { Avatar, Button, Dropdown, Form, Input } from 'antd'
import { FaChevronDown, FaPlus } from 'react-icons/fa'
import { FiLogOut, FiMenu } from 'react-icons/fi'
import authAtom from '../atoms/auth/auth.atom'
import { routes } from '../router'
import { isEqual } from '../context/utils'
import { BiSearch } from 'react-icons/bi'
import { pageTitleAtom } from '../atoms/others/others.atom'
import { FaChevronLeft } from 'react-icons/fa6'

export default function   Header({
  isOpen,
  onOpen,
}) {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { user } = useRecoilValue(authAtom)
  const setPageTitle = useResetRecoilState(pageTitleAtom)  
  const [search=pageTitle, setSearch=setPageTitle] = useRecoilState(pageTitleAtom)
  const { backPath, backBtn, title, actTarget, actBtn, actIcon, actText } = useRecoilValue(pageTitleAtom)
  const items = [
    {
      key: 'profile',
      icon: <GoGear className='!text-base !font-medium' />,
      label: <Link to="/settings" className='!text-sm !font-medium'>Edit Profile</Link>,
    },
    {
      key: 'logout',
      icon: <FiLogOut className='!text-base !font-medium' />,
      label: <Link to="/auth/logout" className='!text-sm !font-medium'>Logout</Link>,
    },
  ]

  const handleAction = () => document.getElementById(actTarget)?.click()
  const goBack = () => {
    navigate(backPath || pathname || -1);
    setPageTitle()
  }
  const pageTitle = title || routes?.find(({ path }) => isEqual(path, pathname))?.label
  return (
    <div className='w-full bg-[#060708] sticky top-0 left-0 right-0 z-20 shadow text-white flex justify-between items-center py-3 px-5 md:px-10 '>
      <div className='flex items-center gap-1 md:hidden'>
        <Button onClick={onOpen} className='!bg-transparent' icon={<FiMenu className='text-2xl text-silver' />} type='text' size='large' />
        <Logo hidden={isOpen} className="!text-2xl !hidden md:block" />
      </div>
      <div className='hidden md:flex items-center gap-2'>
        <Button onClick={goBack} className='!bg-transparent !text-white' type='text' size='large' hidden={!backBtn} icon={<FaChevronLeft />} />
        <p className='text-2xl font-bold capitalize'>{pageTitle || "Not Found"}</p>
      </div>
      <div className='flex items-center gap-2 md:gap-5'>
        <Button onClick={handleAction} className='bg-primary text-[#060708]' type='primary' size='large' hidden={!actBtn} icon={actIcon || <FaPlus />}>{actText || "Add New"}</Button>
        <Form className='hidden md:flex items-center p-0.5 border border-[#1A1D21] rounded-xl'>
          <Form.Item className='p-0 m-0'>
            <Input
              size='large'
              onChange={({target:{value}})=>setSearch({...search, search: value})}
              placeholder='Search'
              className='bg-transparent text-[#9B9C9E] placeholder:text-[#9B9C9E] border-none'
            />
          </Form.Item>
          <Button type='text' htmlType='submit' icon={<BiSearch className='text-[#9B9C9E]' />} />
        </Form>
        <Dropdown menu={{ items }} trigger={["click"]}>
          <div className='flex gap-2 p-1.5 pr-2.5 text-white items-center cursor-pointer border border-[#1A1D21] rounded-3xl'>
            <Avatar
              size={30}
              shape='circle'
              alt={user?.data?.name}
              src={user?.data?.imageUrl}
              style={{ backgroundColor: "#9B9C9E", verticalAlign: 'middle' }}
            >{user?.fullName || "Admin"}</Avatar>
            <p className='text-base font-medium'>{user?.fullName || "Admin"}</p>
            <FaChevronDown className='!text-sm !font-light' />
          </div>
        </Dropdown>
      </div>
    </div>
  )
}